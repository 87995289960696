import { render, staticRenderFns } from "./AppProductCardSkeleton.vue?vue&type=template&id=50f3cf01&scoped=true&"
var script = {}
import style0 from "./AppProductCardSkeleton.vue?vue&type=style&index=0&id=50f3cf01&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f3cf01",
  null
  
)

export default component.exports