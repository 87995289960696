<template>
	<div v-on="$listeners" class="catalog-card">
		<div class="catalog-card__photo">
			<img :src="getImgUrl(item.photo)" />
		</div>

		<div class="catalog-card__content">
			<AppText size="16" line-height="19" weight="600" class="font_Hsemibold mb-5">
				{{ item.name | translate }}
			</AppText>
			<AppText size="14" line-height="17" weight="500" class="font_Hmedium"
				>Кол-во товаров: {{ item.count }}
			</AppText>
		</div>
	</div>
</template>

<script>
import AppText from "@/components/shared-components/AppText";

export default {
	name: "pagination",
	components: {
		AppText,
	},
	props: {
		item: {
			type: Object,
			default: {},
		},
	},
	data() {
		return {
			adminUrl: process.env.VUE_APP_BASE_URL_DOMAIN,
		};
	},
	methods: {
		getImgUrl(imgUrl) {
			if (imgUrl != null) {
				return this.adminUrl + imgUrl;
			} else {
				return imgUrl;
			}
		},
	},
	computed: {},
};
</script>

<style lang="scss" scoped>
.catalog-card {
	position: relative;
	border-radius: 10px;
	height: 150px;
	overflow: hidden;
	transition: 0.3s;
	background-color: #fff;
	display: flex;
	color: #fff;
	align-items: center;
	justify-content: center;
	&::after {
		transition: opacity 0.3s ease-in-out;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.35;
		background: rgb(0, 0, 0);
		z-index: 1;
	}
	&:hover::after {
		opacity: 0.2;
	}

	&:hover {
		box-shadow: 0px 15px 35px rgba(255, 255, 255, 0.05);
		border-color: transparent;
	}

	&__content {
		position: relative;
		z-index: 2;
		text-align: center;
		padding: 0 10px;
	}

	&__photo {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 100%;
			height: 100%;
			//max-height: 65px;
			//max-width: 65px;
			object-fit: cover;
			cursor: pointer;
		}

		p.title {
			position: absolute;
			top: 15px;
			right: 15px;
			border-radius: 3px;
			padding: 5px;
			font-size: 12px;
			font-weight: bolder;
		}
	}
}
</style>

